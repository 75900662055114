import styled from "styled-components"
import { Table as BaseTable } from "../../../elements/Layout/styled"

export const Table = styled(BaseTable)`
  background-color: #fff;

  & tbody tr {
    cursor: pointer;
  }

  & tbody tr:hover,
  & tbody tr.selected {
    background-color: #eee;
  }

  & > thead th:first-child {
    width: 18%;
  }

  & > thead th:nth-child(2),
  & > thead th:nth-child(3) {
    width: 30%;
  }
`

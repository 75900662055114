import styled from "styled-components"

import {
  Main as BaseMain,
  MainContainer as BaseMainContainer,
  Grid as BaseGrid,
  ButtonContainer as BaseButtonContainer,
} from "../../../elements/Layout/styled"

export const Main = styled(BaseMain)``

export const MainContainer = styled(BaseMainContainer)``

export const Grid = styled(BaseGrid)`
  grid-column-gap: 15px;

  & > p {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 12.5px 25px 0 25px;
    order: 0;

    @media screen and (max-width: 767px) {
      padding: 12.5px 7.5px 0 7.5px;
    }
  }

  & > div.table {
    order: 1;
  }

  & > p:nth-child(2) {
    @media screen and (max-width: 767px) {
      order: 2;
      margin-top: 25px;
    }
  }

  & > div.table.inside {
    @media screen and (max-width: 767px) {
      order: 3;
    }
  }

  & > div.submit {
    margin-top: 30px;
    border: 1px solid #ddd;
    grid-column: 1 / span 2;
    order: 2;

    @media screen and (max-width: 767px) {
      border: 1px solid #dd;
      grid-column: 1 / span 1;
      order: 4;
    }
  }
`

export const ButtonContainer = styled(BaseButtonContainer)`
  padding: 30px 25%;

  @media screen and (max-width: 767px) {
    padding: 15px;
  }
`

import React from "react"
import { Table } from "./styled.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSquare, faCheckSquare } from "@fortawesome/pro-light-svg-icons"

const CheckboxTable = ({ data, update }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Select</th>
          <th>Size</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        {data.map(item => {
          return (
            <tr
              key={item.id}
              onClick={e => update("ids", item.id)}
              className={item.checked ? "selected" : ""}
            >
              <td>
                <label>
                  <FontAwesomeIcon
                    icon={item.checked ? faCheckSquare : faSquare}
                    style={{ fontSize: "1.15rem" }}
                  />
                </label>
              </td>
              <td>{item.value}</td>
              <td>{`${item.price}`}</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default CheckboxTable

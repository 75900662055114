import React, { useState } from "react"
import { navigate, graphql } from "gatsby"
import { API_URL, fmtCurrency, getCart, setCart } from "../utils/helpers"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import Header from "../components/Header"
import PageHdr from "../components/PageHdr"
import Footer from "../components/Footer"
import CheckboxTable from "../components/form/CheckboxTable"
import Msg from "../components/form/Msg"
import Submit from "../components/form/Submit"
import {
  Main,
  MainContainer,
  Grid,
  ButtonContainer,
} from "../components/content/Filters/styled"
import { faCartPlus } from "@fortawesome/pro-light-svg-icons"

const Filters = ({ data }) => {
  const [ids, setIds] = useState([])
  const [msg, setMsg] = useState({})

  const handleUpdate = (name, value) => {
    if (ids.indexOf(value) === -1) {
      setIds([...ids, value])
    } else {
      const filtered = ids.filter((val, ind, arr) => {
        return val !== value
      })
      setIds([...filtered])
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setMsg({
      type: "working",
      text: "Working...Please wait.",
    })
    const url = new URL(`${API_URL}/shopFilters`)

    let data = new FormData()
    data.append("ids", JSON.stringify(ids) || "[]")

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        let cart = getCart()
        let newItem = {}
        json.items.forEach(item => {
          newItem = {
            product: item.product,
            filterId: item.filterId,
            qty: item.qty,
            item: item.item,
            subitem: item.subitem,
            price: item.price,
            extras: [],
          }
          let inCart = false
          cart.forEach(cartItem => {
            let qty = 0
            if (
              cartItem.product === "filter" &&
              cartItem.filterId === newItem.filterId
            ) {
              inCart = true
              qty = cartItem.qty + 1
              cartItem.qty = qty
            }
          })
          if (!inCart) {
            cart.push(newItem)
          }
        })
        setCart(cart)
        navigate(json.next)
      } else {
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <Layout>
      <Helmet
        name="Buy AC Filters"
        description="Free shipping on a case of six ac filters from Mechanical Air Conditioning, West Palm Beach, FL."
        slug="filters"
      />
      <Header />
      <PageHdr title="Buy AC Filters" />
      <section>
        <Main>
          <MainContainer className="main-page">
            <article>
              <h2>All prices include a case of 6 filters. Shipping is FREE.</h2>
              <form method="post" onSubmit={e => handleSubmit(e)}>
                <Grid>
                  <p>
                    Behind grill pleated filters are located behind a grill
                    inside the house.
                  </p>
                  <p>
                    Inside or below pleated filters are located inside or below
                    the air conditioning unit.
                  </p>
                  <div className="table">
                    <CheckboxTable
                      update={handleUpdate}
                      data={data.behind.edges.map(item => {
                        return {
                          id: item.node.filterId,
                          value: item.node.size,
                          type: item.node.type,
                          price: fmtCurrency(item.node.price),
                          checked:
                            ids.indexOf(item.node.filterId) !== -1
                              ? true
                              : false,
                        }
                      })}
                    />
                  </div>
                  <div className="table inside">
                    <CheckboxTable
                      className="inside"
                      update={handleUpdate}
                      data={data.inside.edges.map(item => {
                        return {
                          id: item.node.filterId,
                          value: item.node.size,
                          type: item.node.type,
                          price: fmtCurrency(item.node.price),
                          checked:
                            ids.indexOf(item.node.filterId) !== -1
                              ? true
                              : false,
                        }
                      })}
                    />
                  </div>
                  <div className="submit">
                    {msg.type && <Msg data={msg} />}

                    <ButtonContainer
                      className={msg.type === "working" ? "hidden" : ""}
                    >
                      <Submit name="Add Selected to Cart" icon={faCartPlus} />
                    </ButtonContainer>
                  </div>
                </Grid>
              </form>
            </article>
          </MainContainer>
        </Main>
      </section>
      <Footer />
    </Layout>
  )
}

export default Filters

export const query = graphql`
  query FiltersByType {
    behind: allACfilters(filter: { type: { eq: "filterBehind" } }) {
      edges {
        node {
          filterId
          type
          size
          price
        }
      }
    }
    inside: allACfilters(filter: { type: { eq: "filterInside" } }) {
      edges {
        node {
          filterId
          type
          size
          price
        }
      }
    }
  }
`
